<template>
<div class="p-grid p-fluid home">
    <div class="home">
        <div class="prosupport" style="background-image: url('./images/prefondo3.png');">
            <div class="p-grid">
                <div class="p-col-12 p-md-8">
                    <h3 style="color:#727376;"><strong>{{$t('About us')}}</strong></h3>
                    <p style="color:#727376;">
                        {{$t('Company Desc1')}}<br>
                        {{$t('Company Desc2')}}</p>
                </div>
                <div class="p-col-12 p-md-2"></div>
                <div class="p-col-12 p-md-2">
                    <img width="120" src="../assets/images/logo4.png"/>
                </div>
            </div>
        </div>

        <div class="features" style="background:#727376;">
                <div class="p-grid p-justify-center" style="color: white;">
			<h1>WEB PAGE UNDER CONSTRUCTION</h1>
		</div>


                
        </div>


	</div>


</div>
</template>

<script>

export default {
	components: {
    },
	data() {
		return {
            dark: false,
            images: [ 
				{ 
					"itemImageSrc": "images/banner1.jpg", 
					"thumbnailImageSrc": "images/banner1.jpg", 
					"alt": "", 
					"title": "Banner 1" 
				}, 
				{ 
					"itemImageSrc": "images/banner2.jpg", 
					"thumbnailImageSrc": "images/banner2.jpg", 
					"alt": "", 
					"title": "Banner 2" 
				}, 
				{ 
					"itemImageSrc": "images/banner3.jpg", 
					"thumbnailImageSrc": "images/banner3.jpg", 
					"alt": "", 
					"title": "Banner 2" 
				}
			],
			responsiveOptions: [
				{
                    breakpoint: '1024px',
                    numVisible: 5
                },
                {
                    breakpoint: '768px',
                    numVisible: 3
                },
                {
                    breakpoint: '560px',
                    numVisible: 1
                }
			]
			
		}
	},
	productService: null,
	eventService: null,
	created() {
	},
	mounted() {
	},
	methods: {
	},
	computed: {
        introductionClass() {
            return ['introduction', {'introduction-dark': true}];
        }
    }

}
</script>
